<template>
  <div id="orders">
    <tmpl_header :hide="['nav']" />

    <div class="container">
      <sidebar act="orders" />

      <div class="main">
        <div class="tabs">
          <ul>
            <li :class="{ act: status == 0 }" @click="switchStatus(0)">
              全部订单({{ counts ? counts.count0 : 0 }})
            </li>
            <li :class="{ act: status == 1 }" @click="switchStatus(1)">
              待付款({{ counts ? counts.count1 : 0 }})
            </li>
            <li :class="{ act: status == 2 }" @click="switchStatus(2)">
              待发货({{ counts ? counts.count2 : 0 }})
            </li>
            <li :class="{ act: status == 3 }" @click="switchStatus(3)">
              待收货({{ counts ? counts.count3 : 0 }})
            </li>
            <li :class="{ act: status == 4 }" @click="switchStatus(4)">
              已完成({{ counts ? counts.count4 : 0 }})
            </li>
            <li :class="{ act: status == 5 }" @click="switchStatus(5)">
              已取消({{ counts ? counts.count5 : 0 }})
            </li>
          </ul>
        </div>
        <div class="search">
          <input
            type="text"
            v-model="search"
            placeholder="输入订单号搜索"
            @keyup.enter.native="onSearch"
          />
          <a @click="onSearch">搜索</a>
        </div>

        <div class="card thead">
          <p>商品</p>
          <p>单价</p>
          <p>数量</p>
          <p>收货人</p>
          <p>实付款</p>
          <p>订单状态</p>
          <p>订单操作</p>
        </div>

        <div class="empty" v-if="orders.length == 0">没有相关订单</div>

        <div class="card tbody" v-for="order in orders" :key="order.orderId">
          <div class="title">
            <span>下单时间：{{ order.createTime }}</span>
            <span>订单号：{{ order.orderOldCode }}</span>
          </div>
          <ul>
            <li v-for="pro in order.goodsMap" :key="pro.goods_info_id">
              <p>
                <router-link :to="'/goods/view/' + pro.goods_info_id"
                  ><img :src="pro.goods_info_img_id"
                /></router-link>
              </p>
              <p>
                <router-link :to="'/goods/view/' + pro.goods_info_id">
                  <strong>{{ pro.goods_info_name }}</strong></router-link
                >
              </p>
              <p>
                <strong>￥{{ formatPrice(pro.goods_info_price) }}</strong>
              </p>
              <p>
                <strong>x{{ pro.goods_info_num }}</strong>
              </p>
            </li>
          </ul>
          <div>
            <p>
              <strong>{{ order.shippingPerson }}</strong>
            </p>
            <p>
              <strong>￥{{ formatPrice(order.orderOldPrice) }}</strong>
              <span>在线支付</span>
            </p>
            <p>
              <strong>{{ orderStatus(order.orderStatus) }}</strong>
              <router-link :to="'/center/order?order=' + order.orderId"
                >订单详情</router-link
              >
            </p>
            <p>
              <a
                v-for="opt in orderOperates(order.orderStatus)"
                :key="opt.code"
                :data-code="opt.code"
                :data-id="order.orderId"
                class="btn"
                :class="
                  opt.code == 'pay' || opt.code == 'delivery'
                    ? 'btn-primary'
                    : 'btn-info'
                "
                @click="orderOperate"
                >{{ opt.name }}</a
              >
            </p>
          </div>
        </div>

        <pager
          :now="pageNow"
          :show="pageSize"
          :total="pageTotal"
          :rows="rows"
          @onPage="onPage"
          @onChangeRows="onChangeRows"
        ></pager>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import pager from "./pager.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      orders: [],
      counts: null,

      search: "",
      status: 0,
      rows: 0,
      pageSize: 10,
      pageNow: 1,
      pageTotal: 1,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
    pager,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  watch: {},

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/order") },
      });

    const status = this.$route.query.status;
    if (status !== undefined) {
      this.status = parseInt(status, 10);
    }

    api.all([this.loadStatus(), this.loadList()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadList: function (search) {
      const status = {
        0: "",
        1: "23",
        2: "1",
        3: "2",
        4: "10",
        5: "4",
      };
      return api
        .post(apis.center.orders, {
          orderStatus: status[this.status],
          orderNo: search || "",
          pageSize: this.pageSize,
          pageNo: this.pageNow,
        })
        .then((res) => {
          this.orders = res.records;
          this.rows = res.total;
          this.pageNow = res.current;
          this.pageTotal = res.pages;
        });
    },

    loadStatus: function () {
      return api.post(apis.center.orders_count, {}).then((res) => {
        this.counts = res;
      });
    },

    formatPrice(price) {
      let fen = (price * 100) % 100;
      return Math.floor(price) + "." + (fen < 10 ? "0" : "") + fen;
    },

    orderStatus(status) {
      return apis.orderStatus(status);
    },

    orderOperates(status) {
      return apis.orderOperates(status);
    },

    switchStatus(status) {
      this.status = status;
      this.pageNow = 1;
      this.loadList();
    },

    onSearch() {
      this.pageNow = 1;
      this.loadList(this.search);
    },

    onPage(e) {
      this.pageNow = e.page;
      this.loadList();
    },

    onChangeRows(e) {
      this.pageSize = e.rows;
      this.loadList();
    },

    orderOperate(e) {
      var data = e.currentTarget.dataset,
        id = data.id,
        code = data.code;
      switch (code) {
        case "pay":
          this.$router.push({
            path: "/cart/pay",
            query: { order: id },
          });
          break;

        case "ship":
          this.$router.push({
            path: "/center/ship",
            query: { order: id },
          });
          break;

        case "delivery":
          break;
      }
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./orders.less");
</style>
